import clsx from "clsx";
import * as React from "react";

type BackdropProps = {
  className?: string;
  onClick?: () => void;
};

const Backdrop: React.FC<BackdropProps> = ({ className, onClick }) => {
  return (
    <div
      className={clsx(
        "fixed",
        "inset-0",
        "bg-black",
        "bg-opacity-50",
        "z-10",
        className
      )}
      onClick={onClick}
    />
  );
};

export default Backdrop;
