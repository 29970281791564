import { GlobalNavItemType } from "@/types/common/library";
import clsx from "clsx";
import Link from "next/link";
import React from "react";
import { Icon, ICON_TYPE } from "./Icon";

export const GlobalNavigationItem: React.FC<GlobalNavItemType> = ({
  id,
  isActive,
  label,
  href,
  target,
  icon,
  subLinks,
  dataAttributes,
}) => {
  return href ? (
    <Link
      id={id}
      href={href}
      className={clsx(
        "px-4",
        "py-2",
        "transition-all",
        "border-2",
        "border-transparent",
        "hover:bg-button_primary/15",
        "focus:bg-button_primary/15",
        "focus:outline-none",
        {
          ["bg-button_primary/10 text-button_primary"]: isActive,
        }
      )}
      target={target}
      {...dataAttributes}
    >
      <span className={clsx("inline-block", "min-w-[20px]")}>
        <Icon type={ICON_TYPE.REGULAR} icon={icon} />
      </span>
      <span
        className={clsx("ml-2", {
          ["font-semibold "]: isActive,
        })}
      >
        {label}
      </span>
    </Link>
  ) : (
    <div className={clsx("grid", "gap-2")}>
      {subLinks?.map((item) => (
        <p key={`${id}_${item.label}`}>{item.label}</p>
      ))}
    </div>
  );
};
