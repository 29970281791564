"use client";

import { useAuthContext } from "@/contexts/common/AuthContextProvider";
import { useAlert } from "@/hooks/useAlert";
import { SetLoginLocation } from "@/utils/common/login";
import clsx from "clsx";
import { useSearchParams } from "next/navigation";
import { Suspense, useEffect, useState } from "react";
import FancyLoader from "./fancyLoader";
import { useRouter } from "next/navigation";
import Loading from "./loading";
import { ClearAllCache } from "@/utils/common/cache";
import { useTranslation } from "react-i18next";
import { BRANDS } from "@/enums/brands";
import { GetBrand } from "@/utils/common/theme";
import Button from "../library/Button";
import { Icon, ICON_SIZES } from "../library/Icon";

function LoginComponent() {
  const router = useRouter();
  const { login, user, signup, logout } = useAuthContext();
  const searchParams = useSearchParams();
  const { setAlert } = useAlert();
  const { t } = useTranslation("login");

  const [showLogout, setShowLogout] = useState<boolean>(false);
  const [brand, setBrand] = useState<BRANDS>(BRANDS.GAIL);

  const authHint = searchParams.get("gail-auth-hint");

  useEffect(() => {
    setBrand(GetBrand(window.location.host));

    if (user) {
      return;
    }

    router.push("/");
  }, []);

  const handleLogin = async () => {
    SetLoginLocation(window.location.pathname);
    await login();
  };

  const handleSignUp = async () => {
    await signup();
    return;
  };

  const [authHintState, setAuthHintState] = useState<{
    hint: "login" | "signup" | null;
    effect?: () => () => any;
  }>({ hint: null });

  useEffect(() => {
    if (authHintState.hint && authHintState.effect) {
      const effect = authHintState.effect;
      delete authHintState.effect;
      return effect();
    }
  }, [authHintState]);

  useEffect(() => {
    if (authHint && (authHint === "login" || authHint === "signup")) {
      if (authHint === "login") {
        setAuthHintState({
          hint: "login",
          effect() {
            SetLoginLocation(window.location.pathname);
            login();
            return () => {};
          },
        });
      }

      if (authHint === "signup") {
        setAuthHintState({
          hint: "signup",
          effect() {
            SetLoginLocation(window.location.pathname);
            signup();
            return () => {};
          },
        });
      }
    }
  }, [searchParams, authHint]);

  useEffect(() => {
    if (searchParams.get("error")) {
      const description =
        searchParams.get("error_description") || "Please try again.";
      setAlert({
        message: "Login failed: " + description,
        status: "error",
      });
      setShowLogout(true);
      console.error("Login failed: " + description);
    }
  }, [searchParams]);

  if (authHint) {
    return <Loading />;
  }

  let logoLocation: "default" | "solo" | "combined" = "default";

  switch (brand) {
    case BRANDS.GAIL:
      logoLocation = "default";
      break;
    case BRANDS.DEL_TORO:
    case BRANDS.ARNALDO:
    case BRANDS.WEESSIES:
      logoLocation = "solo";
      break;
    case BRANDS.DESOL:
      logoLocation = "combined";
  }

  return (
    <div
      className={clsx(
        "h-screen",
        "bg-[#333354]",
        "text-gray-800",
        "bg-[url('/icons/circleBackgroundFull.svg')]",
        "bg-cover",
        "bg-no-repeat"
      )}
    >
      <div
        className={clsx(
          "fixed",
          "h-screen",
          "w-screen",
          "bg-[#333354]",
          "bg-opacity-70"
        )}
      />
      <div className={clsx("fixed", "z-10")}>
        <div className={clsx("rainbow")}>
          <div
            className={clsx(
              "flex",
              "flex-col",
              "p-8",
              "h-screen",
              "lg:px-32",
              "w-screen",
              "md:min-w-auto",
              "md:w-[50vw]"
            )}
          >
            <div className={clsx("my-auto", "align-middle")}>
              <div
                className={clsx(
                  "login-mask",
                  "rainbow",
                  "w-52",
                  "h-24",
                  "mx-auto",
                  "mb-10"
                )}
              ></div>
              {logoLocation === "combined" && (
                <>
                  <div
                    className={clsx(
                      "text-center",
                      "mt-[-3rem]",
                      "mb-6",
                      "text-gray-500"
                    )}
                  >
                    <Icon icon="x" size={ICON_SIZES.LG} />
                  </div>
                  <div
                    className={clsx(
                      "login-mask",
                      "brand",
                      "rainbow",
                      "w-52",
                      "h-24",
                      "mx-auto",
                      "mb-10"
                    )}
                  ></div>
                </>
              )}
              <div
                className={clsx(
                  "text-sm",
                  "italic",
                  "text-gray-600",
                  "mx-10",
                  "mb-8",
                  "text-center"
                )}
              >
                {t("tagline")}
              </div>
              <Button type="primary" onClick={handleLogin} testId="login" wide>
                {t("login")}
              </Button>
              {showLogout && (
                <Button
                  type="secondary"
                  onClick={() => {
                    ClearAllCache();
                    logout();
                  }}
                  wide
                  className={clsx("mt-4")}
                >
                  {t("logout")}
                </Button>
              )}
              {brand === BRANDS.GAIL && (
                <>
                  <div className="relative flex py-5 items-center">
                    <div className="flex-grow border-t border-gray-400"></div>
                    <span className="flex-shrink mx-4 text-xs">{t("new")}</span>
                    <div className="flex-grow border-t border-gray-400"></div>
                  </div>
                  <Button
                    type="secondary"
                    onClick={handleSignUp}
                    wide
                    testId="signup"
                  >
                    {t("signup")}
                  </Button>
                </>
              )}
            </div>
            <div className={clsx("grid", "grid-cols-2", "gap-2")}>
              <Button
                type="tertiary"
                href="https://www.lula.com/privacy-policy"
                target="_blank"
                compact
              >
                {t("privacy")}
              </Button>
              <Button
                type="tertiary"
                href="https://support.lula.com/"
                target="_blank"
                compact
              >
                {t("support")}
              </Button>
            </div>
          </div>
        </div>
      </div>
      {brand === BRANDS.GAIL && (
        <div
          className={clsx(
            "right-0",
            "w-[40vw]",
            "bottom-0",
            "h-[50vh]",
            "fixed",
            "bg-[url('/icons/GAIL_teaser.png')]",
            "bg-cover",
            "rounded-tl-xl",
            "border-[#ff5601]",
            "border-2"
          )}
        >
          &nbsp;
        </div>
      )}
    </div>
  );
}

export default function Login() {
  return (
    <Suspense fallback={<FancyLoader />}>
      <LoginComponent />
    </Suspense>
  );
}
