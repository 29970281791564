type PhoneFormatter = (value: string) => string;

export const phoneNumberRegex =
  /^(\d{1,4} )?(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/;

export const genericNumberPattern = "[0-9-]+";

export const formatPhoneNumber: PhoneFormatter = (value) => {
  // If the input value is empty (e.g., if the user deletes the input), just return the value
  if (!value) return value;

  // Remove any non-digit values from the input
  const phoneNumber = value.replace(/[^\d]/g, "");

  // The length of the phone number is used to know when to apply our formatting
  const phoneNumberLength = phoneNumber.length;

  // If the phone number is less than four digits, we return the value with no formatting
  if (phoneNumberLength < 4) return phoneNumber;

  // If the phone number is between four and six digits, we format the area code
  if (phoneNumberLength < 7) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
  }

  // If the phone number is between seven and ten digits, we format the area code and the next three digits
  if (phoneNumberLength < 11) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
      3,
      6
    )}-${phoneNumber.slice(6, 10)}`;
  }

  // If the phone number is more than ten digits, we assume it starts with a country code. We format the number accordingly,
  // by isolating the country code (everything but the last ten digits), the area code (the next three digits), the next three digits,
  // and the last four digits
  return `${phoneNumber.slice(0, phoneNumberLength - 10)} (${phoneNumber.slice(
    phoneNumberLength - 10,
    phoneNumberLength - 7
  )}) ${phoneNumber.slice(
    phoneNumberLength - 7,
    phoneNumberLength - 4
  )}-${phoneNumber.slice(phoneNumberLength - 4)}`;
};

export const getNameWithLastInitial = (name: string) => {
  if (!name) {
    return "";
  }

  const names = name.split(" ");
  const firstName = names[0];
  const lastName = names[names.length - 1];

  if (names.length === 1) {
    return `${firstName}`;
  }

  return `${firstName} ${lastName[0]}.`;
};

export const getNameInitials = (name: string) => {
  if (!name) {
    return "";
  }

  const names = name.split(" ");
  const firstName = names[0];
  const lastName = names[names.length - 1];

  if (names.length === 1) {
    return `${firstName[0]}`;
  }

  return `${firstName[0]}${lastName[0]}`;
};
