import { BRANDS } from "@/enums/brands";

export const GetBrand = (host: string): BRANDS => {
  const override = window.localStorage.getItem("brand");

  if (override) {
    return override as BRANDS;
  }

  if (host.includes("desol")) {
    return BRANDS.DESOL;
  }

  return BRANDS.GAIL;
};
