import * as React from "react";
import clsx from "clsx";
import { NayLayoutProps } from "./NavLayout";
import NavLayoutSidebarFooter from "./NavLayoutSidebarFooter";
import { GlobalNavigationItem } from "./NavLayoutItem";
import Icon from "./Icon";

type SidebarProps = {
  isMobileMenuOpen?: boolean;
  sideBarRef?: React.RefObject<HTMLElement>;
  isMobile?: boolean;
  className?: string;
  customLogo?: React.ReactNode;
  sideNavMessage?: string;
} & Pick<NayLayoutProps, "user" | "userActions" | "menuSections">;

const NavLayoutSidebar: React.FC<SidebarProps> = ({
  className,
  sideBarRef,
  menuSections = [],
  user,
  isMobileMenuOpen,
  isMobile,
  userActions,
  customLogo,
  sideNavMessage,
}) => {
  const hasFooter = !!user?.id && !!user?.name;

  return (
    <aside
      data-open={!!isMobileMenuOpen}
      id="nayLayout_sidebar"
      ref={sideBarRef}
      className={clsx(
        "bg-white",
        "dark:bg-gray-800",
        "px-4",
        "pb-4",
        "self-stretch",
        "flex",
        "flex-col",
        "items-stretch",
        "justify-start",
        "gap-2",
        "fixed",
        "top-16",
        "left-0",
        "right-0",
        "bottom-0",
        "z-50",
        "transition-all",
        "w-[80%]",
        "md:w-full",
        "md:sticky",
        "md:top-0",
        "md:translate-x-0",
        "md:bg-[#F5F6FA]",
        "md:h-screen",
        className,
        {
          ["translate-x-0"]: isMobile && isMobileMenuOpen,
          ["-translate-x-full"]: isMobile && !isMobileMenuOpen,
        }
      )}
    >
      {!isMobile && (
        <div className="mt-8 hidden text-center text-lulaRed md:block">
          {customLogo ? customLogo : <Icon icon="lula-logo" />}
        </div>
      )}
      {sideNavMessage && (
        <div
          className={clsx("mt-8", "p-4", "rounded-xl", "bg-panel", "shadow")}
        >
          {sideNavMessage}
        </div>
      )}
      <div className={clsx("h-full", "overflow-auto", "mx-[-1rem]")}>
        {!!menuSections.length &&
          menuSections
            .filter((section) => section.position !== "bottom")
            .map((section) => (
              <div key={`section_${section.name}`}>
                <label
                  className={clsx(
                    "pl-4",
                    "uppercase",
                    "text-gray-600",
                    "dark:text-gray-400",
                    "mt-6",
                    "mb-2",
                    "block"
                  )}
                >
                  {section.name}
                </label>
                <div className={clsx("grid", "self-stretch")}>
                  {section.items.map((item) => (
                    <GlobalNavigationItem
                      key={`${item.label}_${item.icon}`}
                      {...item}
                    />
                  ))}
                </div>
              </div>
            ))}
      </div>
      {hasFooter && (
        <NavLayoutSidebarFooter
          user={user}
          userActions={userActions}
          bottomSection={
            (!!menuSections.length &&
              menuSections.find((section) => section.position === "bottom")) ||
            undefined
          }
        />
      )}
    </aside>
  );
};

export default NavLayoutSidebar;
