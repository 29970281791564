import * as React from "react";
import clsx from "clsx";
import { User } from "@/contexts/common/AuthContextProvider";
import { getNameInitials } from "@/utils/library/formatters";
import Image from "next/image";

type Props = {
  img?: User["img"];
  name: User["name"];
};
const UserAvatar: React.FC<Props> = ({ img, name }) => {
  if (!img && !name) return null;

  if (!img) {
    return (
      <span
        className={clsx(
          "inline-flex",
          "h-6 w-6",
          "items-center",
          "justify-center",
          "rounded-full",
          "bg-panel0"
        )}
      >
        <span
          className={clsx(
            "text-xs",
            "font-medium",
            "leading-none",
            "text-white"
          )}
        >
          {getNameInitials(name || "")}
        </span>
      </span>
    );
  }

  return (
    <Image
      className={clsx(
        "inline-block",
        "h-6 w-6",
        "rounded-full",
        "object-cover"
      )}
      src={img}
      alt={name}
      width={24}
      height={24}
    />
  );
};

export default UserAvatar;
