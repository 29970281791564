import clsx from "clsx";
import Link from "next/link";
import * as React from "react";
import Icon from "./Icon";
import Button from "./Button";

type NavLayoutHeaderProps = {
  customLogo?: React.ReactNode;
  onToggleMenu?: () => void;
};

const NavLayoutHeader: React.FC<NavLayoutHeaderProps> = ({
  customLogo,
  onToggleMenu,
}) => {
  return (
    <header
      data-testid="nayLayout_header"
      className={clsx(
        "bg-white",
        "dark:bg-gray-800",
        "grid",
        "grid-cols-3",
        "px-4",
        "py-2",
        "md:py-[0.875rem]",
        "fixed",
        "top-0",
        "left-0",
        "right-0",
        "z-20",
        "min-h-16"
      )}
    >
      <div />
      <Link
        className={clsx("flex", "justify-center", "items-center")}
        href={"/"}
      >
        {customLogo ? customLogo : <Icon icon="lula-logo" title="Lula" />}
      </Link>
      <div className={clsx("flex", "justify-end", "items-center", "gap-4")}>
        <Button
          compact
          type="none"
          onClick={onToggleMenu}
          className={clsx("leading-none")}
        >
          <Icon icon="bars" title="Toggle Menu" />
        </Button>
      </div>
    </header>
  );
};

export default NavLayoutHeader;
