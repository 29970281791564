export enum MessagingVerificationStatus {
  NoBusinessProfile = "NoBusinessProfile",
  BusinessProfileApproved = "BusinessProfileApproved",
  BrandRegistrationPending = "BrandRegistrationPending",
  BrandRegistrationApproved = "BrandRegistrationApproved",
  CampaignPending = "CampaignPending",
  CampaignApproved = "CampaignApproved",
  None = "None",
}

export enum MessagingColumnTypes {
  ContactNumber = "contact_number",
  MessageCount = "message_count",
  MessageType = "message_type",
  CreatedAt = "created_at",
}

export enum MessageTypes {
  SMS = "SMS",
  WhatsApp = "WhatsApp",
  Web = "Web",
}
