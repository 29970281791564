/**
 * @deprecated This older endpoint is deprecated and is being phased out, please use the new endpoint when possible.
 */
export const BASE_GAIL_API = process.env.BASE_GAIL_API || "";

export const GAIL_API = process.env.NEXT_PUBLIC_GAIL_API || "";

export enum PUBLIC_API_ROUTES {
  AUTH_GET_STARTED = "/api/public/onboarding/get-started",
  SETUP_AGENCY = "/api/public/onboarding/setup-agency",
  SETUP_AGENCY_UPDATE_CONTACT = "/api/public/onboarding/update-contact",
  ONBOARDING_CREATE_CONTACT = "/api/public/onboarding",
  ONBOARDING_INITIALIZE_STRIPE = "/api/public/onboarding/checkout",
}
